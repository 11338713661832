export const ShopContent = [
    {
        title: "Spominek Hematit - Barit",
        description: "Unikaten hematitno baritni spominek iz našega rudnika.",
        images_directory: "spominek_hematit_barit",
    },
    {
        title: "Spominek Limonit",
        description: "Unikaten limonitni spominek iz našega rudnika.",
        images_directory: "spominek_limonit"
    },
    {
        title: "Spominek Galenit",
        description: "Unikaten galenitni spominek iz našega rudnika.",
        images_directory: "spominek_galenit"
    },
    {
        title: "Obesek s Hematitom",
        description: "Hematit je kristal, ki krepi našo vez z zemljo in nam daje občutek varnosti in domačnosti. Deluje tako, da uravnoteži energijo telesa, uma in duha. Hematit čisti in ščiti auro, prizemljuje, odganja negativne energije odpravlja strahove, zmanjšuje vsakodnevno napetost in izboljša krvni pretok. Pri zdravljenju pa vpija bolečino iz telesa na mestu, kamor ga položimo.",
        images_directory: "hematitni_nakit"
    },
    {
        title: "Magnet Rudnika Sitarjevec Litija",
        description: "Magnet predstavlja izjemne hitrorastoče limonitne kapniške strukture iz rudnika Sitarjevec Litija.",
        images_directory: "magnet_rudnika_sitarjevec"
    },

    {
        title: "Magnet Litijski srebernik",
        description: "Leta 1886 so v litijski topilnici uspešno pridobili tudi srebro, ki se v manjši količini pojavlja v svinčevi rudi. V takratno glavno državno kovnico na Dunaju so poslali 3,6999 kg srebra, iz katerega so izdelali spominske srebrnike – litijske tolarje. Na prednji strani je upodobljena takratna Litijska topilnica, vlak, cerkev sv. Nikolaja, hrib Sitarjevec in rudniška žičnica do topilnice. Na drugi strani je naziv rudnika s simboličnim napisom »prvi srebrni pogled«.",
        images_directory: "magnet_litijski_srebernik"
    },
    {
        title: "Knjiga V temnih globinah Sitarjevca",
        description: "Knjigo v kateri se nahaja 6 kratkih zgodbic je napisala Darinka Kobal. Po opisu pripovedovalcev zgodb je knjigo ilustrirala Anita Vozelj.",
        images_directory: "knjiga_v_temnih_globinah_sitarjevca"
    },
    {
        title: "Knjiga Stara sodnija",
        description: "Knjigo v kateri se nahajajo 4 zgodbe  je napisala Darinka Kobal. Za izvrstne ilustracije pa je poskrbela Anita Vozelj",
        images_directory: "knjiga_stara_sodnija"
    },
    {
        title: "Knjiga Ko Litija še ni bila Litija",
        description: "V knjigi pisateljica Darinka Kobal opisuje kje so se staroselci nekoč naselili, kako in s čim so se preživljali in v kakšnih domovih so živeli. Anita Vozelj je pisateljičino besedilo podprla s lepimi ilustracijami.",
        images_directory: "knjiga_ko_litija_se_ni_bila_litija"
    },
    {
        title: "Magnet Litko",
        description: "Litko je rudar in maskota litijskega karnevala, ki razveseljuje obiskovalce na vseh večjih dogodkih v Litiji.  Domuje v rudniku Sitarjevec in se skriva v njegovih globinah. Njegova podoba krasi tudi magnet.",
        images_directory: "magnet_litko"
    },
    {
        title: "Nahrbtnik Litko",
        description:"Litko je rudar in maskota litijskega karnevala, ki razveseljuje obiskovalce na vseh večjih dogodkih v Litiji.  Domuje v rudniku Sitarjevec in se skriva v njegovih globinah. Njegova podoba krasi tudi priročen nahrbtnik iz neprepustnega materiala.",
        images_directory: "nahrbtnik_litko"
    },
    {
        title: "Svinčnik Rudnik Sitarjevec",
        description: "Uporaben spominek - črn svinčnik z imenom in logom rudnika.",
        images_directory: "svincnik_rudnik_sitarjevec"
    },
];
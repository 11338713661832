import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';

function ShopElement({column, title, description, slug, imagesData}) {

    console.log(imagesData);

    return (
        <div className={`${column}`}>
            <Link to={`/obisci-rudnik/trgovina-in-spominki/${slug}`}>
                <div className="team">
                    <div className="thumbnail">
                        { imagesData.nodes.length > 0 && (
                            <BackgroundImage Tag="div" className="service-details-content" fluid={imagesData.nodes[0].childImageSharp.fluid} />
                        )}
                    </div>
                    <div className="content">
                        <h4 className="title">{title}</h4>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default ShopElement;
import React from 'react';
import Header from "../../components/headers/Header";
import CallToAction from "../../components/layouts/CallToAction";
import ShopHeader from "../../components/shop/ShopHeader";
import Footer from "../../components/layouts/Footer";
import ShopDescription from "../../components/shop/ShopDescription";
import SEO from "../../components/seo/Seo";
import { graphql } from "gatsby";

function TrgovinaInSpominki(props) {
    return (
        <div>
            <SEO title="Trgovina in spominki" description="V recepciji našega rudnika lahko kupite različne spominke, magnet Sitarjevec ali magnet litijski srebrnik. Prodajamo tudi nakit iz hematita, spominke iz različnih rud in mineralov, med drugim spominek hematit-barit, limonit, galenit iz rudnika Sitarjevec Litija. Na voljo za nakup so tudi 3 knjige: V temnih globinah Sitarjevca, Stara sodnija, Ko Litija še ni bila Litija."/>
            <Header/>
            <ShopHeader/>
            <ShopDescription images={props.data}/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export const query = graphql`
    query {
        spominek_hematit_barit: allFile(filter: {relativePath: {regex: "/rudnik/shop/spominek_hematit_barit/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        spominek_limonit: allFile(filter: {relativePath: {regex: "/rudnik/shop/spominek_limonit/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        spominek_galenit: allFile(filter: {relativePath: {regex: "/rudnik/shop/spominek_galenit/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        hematitni_nakit: allFile(filter: {relativePath: {regex: "/rudnik/shop/hematitni_nakit/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magnet_rudnika_sitarjevec: allFile(filter: {relativePath: {regex: "/rudnik/shop/magnet_rudnika_sitarjevec/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magnet_litijski_srebernik: allFile(filter: {relativePath: {regex: "/rudnik/shop/magnet_litijski_srebernik/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        knjiga_v_temnih_globinah_sitarjevca: allFile(filter: {relativePath: {regex: "/rudnik/shop/knjiga_v_temnih_globinah_sitarjevca/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        
        knjiga_stara_sodnija: allFile(filter: {relativePath: {regex: "/rudnik/shop/knjiga_stara_sodnija/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        
        knjiga_ko_litija_se_ni_bila_litija: allFile(filter: {relativePath: {regex: "/rudnik/shop/knjiga_ko_litija_se_ni_bila_litija/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        
        magnet_litko: allFile(filter: {relativePath: {regex: "/rudnik/shop/magnet_litko/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        nahrbtnik_litko: allFile(filter: {relativePath: {regex: "/rudnik/shop/nahrbtnik_litko/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        
        svincnik_rudnik_sitarjevec: allFile(filter: {relativePath: {regex: "/rudnik/shop/svincnik_rudnik_sitarjevec/g"}}) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`;

export default TrgovinaInSpominki;


import React from 'react';
import ShopItems from "./ShopItems";

function ShopDescription({images}) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center d-flex">
                        <div className="col-12 col-lg-8 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Trgovina z izdelki</h2>
                                <p className="description">
                                    V recepciji našega rudnika lahko kupite različne spominke, magnet Sitarjevec ali magnet litijski srebrnik. Prodajamo tudi nakit iz hematita, spominke iz različnih rud in mineralov, med drugim spominek hematit-barit, limonit, galenit iz rudnika Sitarjevec Litija. Na voljo za nakup so tudi 3 knjige: V temnih globinah Sitarjevca, Stara sodnija, Ko Litija še ni bila Litija.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <ShopItems images={images} column="col-lg-4 col-md-6 col-sm-6 col-12" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopDescription;